import { compact } from '@leland-dev/leland-ui-library';
import { isEmpty, isNil, omitBy } from 'lodash-es';
import type { NextRouter } from 'next/router';
import type { UrlObject } from 'url';

import {
  type GoalName,
  ReferralType,
  type Scalars,
  type SkuTier,
} from '../__generated-gql-types__/globalTypes';

import { APPLICANT_SITE_URL, isServer } from './constants';
import { REFERRAL_TYPE_MAP } from './referrals';
import { REFERRAL_CODE_KEY, REFERRAL_TYPE_KEY } from './storage';

/**
 * @param categorySlug required (even if null/undefined) since it is preferred over fallback redirection
 */
export const getCoachProfileUrl = (
  coachSlug: string,
  categorySlug: Possible<string>,
) =>
  `${APPLICANT_SITE_URL}/coach/${coachSlug}${
    categorySlug ? `/${categorySlug}` : ''
  }`;

export const getCoachVouchUrl = (coachSlug: string) =>
  `${APPLICANT_SITE_URL}/vouch/${coachSlug}`;

export const getCoachReferralUrl = (coachSlug: string) =>
  `${getUrlString(getCoachProfileUrl(coachSlug, null), {
    [REFERRAL_CODE_KEY]: coachSlug,
    [REFERRAL_TYPE_KEY]: REFERRAL_TYPE_MAP[ReferralType.COACH_REFER_CUSTOMER],
  })}`;

export const getPackageListingUrl = ({
  coachSlug,
  packageSlug,
  packageId,
  categorySlug,
  isPlan,
  tier,
}: {
  packageSlug: Possible<string>;
  packageId: Scalars['PackageUrn']['output'];
} & (
  | { coachSlug: string; categorySlug?: never; isPlan?: false; tier?: never }
  | { coachSlug?: never; categorySlug: string; isPlan: true; tier?: SkuTier }
)) =>
  isPlan
    ? getUrlString(
        `${APPLICANT_SITE_URL}/package/${categorySlug}/${packageSlug ?? packageId}`,
        tier ? { coachTier: tier } : {},
      )
    : `${APPLICANT_SITE_URL}/coach/${coachSlug}/p/${packageSlug ?? packageId}`;

export const getSRPUrl = ({
  goal,
  categorySlug,
  subCategorySlug,
}: {
  goal: Possible<GoalName>;
  categorySlug: Possible<string>;
  subCategorySlug: Possible<string>;
}): string => {
  const urlFragments = ['search'];
  if (goal && categorySlug) {
    urlFragments.push(goal.toLowerCase());
    urlFragments.push(categorySlug);
    if (subCategorySlug) urlFragments.push(subCategorySlug);
  }
  return `${APPLICANT_SITE_URL}/${urlFragments.join('/')}`;
};

export const redirectToLogin = async (router: NextRouter): Promise<boolean> => {
  return router.push(getLoginUrlObject(router));
};

export const getLoginUrlObject = (router: NextRouter): UrlObject => {
  const query = isServer
    ? {
        ...router.query,
      }
    : {
        ...router.query,
        redirect_url: window.location.href,
      };
  return getUrlObject('/login', query);
};

export const getUrlObject = (
  pathname: string,
  query: UrlObject['query'],
): UrlObject => ({
  // trim query params from pathname
  pathname: pathname.replace(/[?#].*/, ''),
  query,
});

type BaseType = Possible<string | number | boolean>;
export const getUrlString = (
  url: string,
  queryParams: Record<string, BaseType | BaseType[]>,
  useHash?: boolean,
): string => {
  if (isEmpty(queryParams)) {
    return url;
  }

  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  const paramsString = Object.entries(omitBy(queryParams, isNil))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .filter(compact)
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join('&');
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
  /* eslint-enable @typescript-eslint/no-unsafe-argument */

  const char = useHash ? '#' : '?';
  return `${url}${url.includes(char) ? '&' : char}${paramsString}`;
};

export const isValidHttpsUrl = (url: string): boolean => {
  try {
    const givenURL = new URL(url);
    return givenURL.protocol === 'https:';
  } catch {
    return false;
  }
};

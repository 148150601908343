import React, {
  createContext,
  type PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import {
  type LexContextLexFragment,
  useLexContextLexListQuery,
} from './__generated-gql-types__/LexContext.generated';

// list of Lex keys to evaluate. Please actively remove the key if it's been cleaned up completely
export enum LexSlug {
  FEATURE_INVOICE_CUSTOMER = 'feature-invoice-customer',
  FEATURE_OFFER_TRIAL_OPT_IN = 'feature-offer-trial-opt-in',
  FEATURE_COACH_SMART_PRICING = 'feature-coach-smart-pricing',
  FEATURE_LINKEDIN_FLOW = 'feature-applicant-free-month',
  FEATURE_CLIENT_TYPEAHEAD = 'feature-client-typeahead',
  FEATURE_PACKAGE_HOURLY_UNIFICATION = 'feature-package-hourly-unification',
  FEATURE_RECOMMENDED_OFFERINGS = 'feature-recommended-offerings',
  TRIAL_SESSION_ONBOARDING_MODAL = 'trial-session-onboarding-modal',
  PUBLIC_OPPORTUNITIES = 'public-opportunities',
  MULTIPLE_LINKEDIN_SKUS = 'multiple-linkedin-skus',
  FEATURE_CONFERENCE_INTEGRATION = 'feature-conference-integration',
  INBOX_CLIENT_SEARCH = 'inbox-client-search',
}
const LEX_SLUGS_TO_EVALUATE: LexSlug[] = Object.values(LexSlug);

const MISSING_LEX_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <LexProvider>';

interface LexContext {
  isLexEnabled: (lexKey: LexSlug) => boolean;
  isLexLoading: boolean;
}

export const LexContext = createContext<LexContext>({
  get isLexEnabled(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
  get isLexLoading(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
});

// to prevent potential layout shift, initialize some fully ramped lex slugs by default
// NOTE: should only be temporary until the Lex is removed
const DEFAULT_INIT_LIST: LexContextLexFragment[] = [];

/**
 * LeX is short for Leland Experiment to enable A/B testing.
 * `useLex` to check whether the experiment/feature is ramped to the current user/guest
 */
export const useLex: () => LexContext = () =>
  useContext<LexContext>(LexContext);

const LexContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // TODO: listen to user data change to reload rampedExperiments
  const { data, loading } = useLexContextLexListQuery({
    variables: { slugs: LEX_SLUGS_TO_EVALUATE },
    skip: LEX_SLUGS_TO_EVALUATE.length === 0,
  });

  const value: LexContext = useMemo(() => {
    const lexIsRampedMap = (data?.lexListBySlugs ?? DEFAULT_INIT_LIST).reduce<
      Record<string, boolean>
    >((map, lex) => {
      map[lex.slug] = lex.isRamped;
      return map;
    }, {});

    return {
      isLexEnabled: (slug: LexSlug) => !!lexIsRampedMap[slug],
      isLexLoading: loading,
    };
  }, [data?.lexListBySlugs, loading]);

  return <LexContext.Provider value={value}>{children}</LexContext.Provider>;
};

export default LexContextProvider;

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  concatPagination,
  isSubscriptionOperation,
  type StoreObject,
} from '@apollo/client/utilities';
import { type Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { compact } from '@leland-dev/leland-ui-library';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import merge from 'deepmerge';
import { createClient } from 'graphql-ws';
import { isEqual, keyBy } from 'lodash-es';
import { useMemo } from 'react';

import generatedIntrospection from '../__generated-gql-types__/fragments';
import { isServer } from '../utils/constants';
import {
  IMPERSONATED_USER_KEY,
  LelandImpersonation,
} from '../utils/impersonation';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const LELAND_API_URL = process.env.NEXT_PUBLIC_LELAND_API_URL;
const LELAND_WSS_URL = process.env.NEXT_PUBLIC_LELAND_WSS_URL;
if (!LELAND_API_URL) {
  throw new Error('Missing env var: NEXT_PUBLIC_LELAND_API_URL');
}
if (!LELAND_WSS_URL) {
  throw new Error('Missing env var: NEXT_PUBLIC_LELAND_WSS_URL');
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const uploadLink = createUploadLink({
  uri: `${LELAND_API_URL}/graphql`,
  credentials: 'include', // necessary for local development
}) as ApolloLink;

const headerLink = setContext((_, { headers }) => {
  let impersonatedUserId: Nullable<string>;
  try {
    impersonatedUserId =
      LelandImpersonation.getImpersonatedUserIdFromBrowserCookie();
  } catch {
    impersonatedUserId = null;
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // return the headers to the context so httpLink can read them
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      ...(impersonatedUserId
        ? { [IMPERSONATED_USER_KEY]: impersonatedUserId }
        : {}),
      'x-leland-timezone': timezone,
    },
  };
});

const typenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const preservedFields: Record<string, unknown> = {};
    let preservedFieldsCounter = 1;

    /**
     * `File` objects are not serializable by `JSON.stringify`, so we need to
     * preserve them in a way that can be deserialized later.
     *
     * This replacer/reviver pair is used to serialize/deserialize the variables
     * in such a way that `File` objects are preserved.
     */
    const replacer = <T>(key: string, value: T): Optional<T | string> => {
      // preserve files
      if (value instanceof File) {
        const preservedFieldId = `__preserved_field_${preservedFieldsCounter++}`;
        preservedFields[preservedFieldId] = value;
        return preservedFieldId;
      }
      // omit __typename
      if (key === '__typename') {
        return undefined;
      }
      // all else is fine to serialize/deserialize
      return value;
    };
    const reviver = <T>(key: string, value: T): Optional<T> => {
      // restore files
      if (typeof value === 'string' && value.startsWith('__preserved_field_')) {
        return preservedFields[value] as Optional<T>;
      }
      return value;
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables, replacer),
      reviver,
    );
  }
  return forward(operation);
});

const createApolloClient = (
  getAccessTokenSilently:
    | Auth0ContextInterface['getAccessTokenSilently']
    | null = null,
): ApolloClient<NormalizedCacheObject> => {
  let authMiddleware: ApolloLink | null = null;
  let wsLink: GraphQLWsLink | null = null;
  if (!isServer && getAccessTokenSilently) {
    authMiddleware = setContext(async (_, { headers, ...rest }) => {
      let accessToken: string | undefined | null;
      try {
        accessToken = await getAccessTokenSilently();
      } catch (e) {
        console.warn(e);
        accessToken = null;
      }
      if (accessToken) {
        return {
          ...rest,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          headers: {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          },
        };
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { headers, ...rest };
      }
    });
    wsLink = new GraphQLWsLink(
      createClient({
        url: `${LELAND_WSS_URL}/graphql`,
        lazy: true,
        connectionAckWaitTimeout: 30000,
        keepAlive: 30000,
        connectionParams: async () => {
          let accessToken: string | undefined | null;
          try {
            accessToken = await getAccessTokenSilently();
          } catch (e) {
            console.warn(e);
            accessToken = null;
          }
          return {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
          };
        },
      }),
    );
  }

  const httpsLink = ApolloLink.from(
    [typenameMiddleware, authMiddleware, uploadLink].filter(compact),
  );

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: headerLink.concat(
      isServer || !wsLink
        ? httpsLink
        : ApolloLink.split(
            ({ query }) => {
              return isSubscriptionOperation(query);
            },
            wsLink,
            httpsLink,
          ),
    ),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
            coachingRelationshipsForCurrentCoach: {
              keyArgs: ['statuses', 'archivedByCoach', 'blockedByCoach'],
              merge: (existing: StoreObject = {}, incoming: StoreObject) => {
                // if incoming and existing appear to be the same, check further
                if (
                  incoming?.total === existing?.total &&
                  Array.isArray(incoming?.results) &&
                  Array.isArray(existing?.results) &&
                  incoming.results.length === existing.results.length
                ) {
                  const existingById = keyBy(
                    existing.results,
                    ({ __ref, id }) => (__ref ?? id) as string,
                  );
                  // if all results are the same (i.e. the only change is reordering),
                  // then just use the incoming results (possibly subscription update)
                  if (
                    incoming.results.every(
                      ({ __ref = '', id = '' }) =>
                        !!(
                          existingById[__ref as string] ??
                          existingById[id as string]
                        ),
                    )
                  ) {
                    return incoming;
                  }
                }

                return merge(existing, incoming, {
                  // combine arrays using object equality (like in sets)
                  arrayMerge: (
                    initialArray: unknown[],
                    newArray: unknown[],
                  ) => [
                    ...initialArray,
                    ...newArray.filter((n) =>
                      initialArray.every((i) => !isEqual(n, i)),
                    ),
                  ],
                });
              },
              read: (existing: StoreObject, { readField }) => {
                if (!existing) {
                  return existing;
                }

                const sortedResults = Array.isArray(existing.results)
                  ? ([...existing.results] as StoreObject[]).sort((a, b) => {
                      if (
                        readField('hasPendingOrder', a) !==
                        readField('hasPendingOrder', b)
                      ) {
                        return readField('hasPendingOrder', a) ? -1 : 1;
                      }

                      if (
                        (readField('hasPendingIntroRequest', a) ??
                          readField('hasPendingIntroMessage', a)) !==
                        (readField('hasPendingIntroRequest', b) ??
                          readField('hasPendingIntroMessage', b))
                      ) {
                        return (readField('hasPendingIntroRequest', a) ??
                          readField('hasPendingIntroMessage', a))
                          ? -1
                          : 1;
                      }

                      const aDate = Math.max(
                        readField('lastSortingUpdatedAt', a) ?? 0,
                        readField('lastMessagedAt', a) ?? 0,
                        readField('lastPurchasedAt', a) ?? 0,
                        readField('lastScheduledAt', a) ?? 0,
                        readField('createdAt', a) ?? 0,
                      );
                      const bDate = Math.max(
                        readField('lastSortingUpdatedAt', a) ?? 0,
                        readField('lastMessagedAt', b) ?? 0,
                        readField('lastPurchasedAt', b) ?? 0,
                        readField('lastScheduledAt', b) ?? 0,
                        readField('createdAt', b) ?? 0,
                      );

                      return bDate - aDate;
                    })
                  : [];

                return {
                  ...existing,
                  results: sortedResults,
                };
              },
            },
            ordersByCoupon: {
              keyArgs: ['couponUrn'],
              merge: (existing: StoreObject = {}, incoming: StoreObject) => {
                return merge(existing, incoming, {
                  // combine arrays using object equality (like in sets)
                  arrayMerge: (
                    initialArray: unknown[],
                    newArray: unknown[],
                  ) => [
                    ...initialArray,
                    ...newArray.filter((n) =>
                      initialArray.every((i) => !isEqual(n, i)),
                    ),
                  ],
                });
              },
            },
            groupClassesForCoach: {
              keyArgs: false,
              merge: (existing: StoreObject = {}, incoming: StoreObject) =>
                merge(existing, incoming, {
                  // combine arrays using object equality (like in sets)
                  arrayMerge: (
                    initialArray: unknown[],
                    newArray: unknown[],
                  ) => [
                    ...initialArray,
                    ...newArray.filter((n) =>
                      initialArray.every((i) => !isEqual(n, i)),
                    ),
                  ],
                }),
            },
          },
        },
        Coach: {
          fields: {
            educationExperiences: { merge: false },
            categoryInfo: {
              keyArgs: ['category', ['id'], 'offerings'],
            },
          },
        },
        Company: {
          keyFields: ['name', 'domain'],
        },
        CalendarConnection: {
          keyFields: ['calendarId'],
        },
      },
    }),
  });
};

export const initializeApollo = (
  initialState: Nullable<NormalizedCacheObject> = null,
  getAccessTokenSilently:
    | Auth0ContextInterface['getAccessTokenSilently']
    | null = null,
): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient =
    apolloClient ?? createApolloClient(getAccessTokenSilently);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: unknown[], sourceArray: unknown[]) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = <TPageProps>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: {
    props: TPageProps;
  },
): {
  props: TPageProps;
} => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useApollo = <TPageProps extends Record<string, unknown>>(
  pageProps: TPageProps,
): ApolloClient<NormalizedCacheObject> => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const { getAccessTokenSilently } = useAuth0();
  const store = useMemo(
    () =>
      initializeApollo(
        state as Nullable<NormalizedCacheObject>,
        getAccessTokenSilently,
      ),
    [getAccessTokenSilently, state],
  );
  return store;
};

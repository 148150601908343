import React, { createContext, useContext, useMemo } from 'react';

import { isAvailabilityOnboarded } from '../utils/availability';
import {
  MAXIMUM_CATEGORY_LIMIT,
  SUBSCRIBER_DUMMY_COACH_URN,
} from '../utils/constants';
import { isEmptyString } from '../utils/str';

import { LexSlug, useLex } from './LexContext';
import { useOnboardingStatusesContextUserQuery } from './__generated-gql-types__/OnboardingStatusesContext.generated';

interface OnboardingStatuses {
  profileDone: boolean;
  categoriesDone: boolean;
  pricingServicesDone: boolean;
  onboardingSessionDone: boolean;
  stripeDone: boolean;
  availabilityDone: boolean;
}

export interface OnboardingStatusesContext {
  statuses: OnboardingStatuses | null;
  areStatusesLoading: boolean;
  errorLoadingStatuses?: Error;
  refreshStatuses: () => void;
}

const MISSING_ONBOARDING_STATUS_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <OnboardingStatusesContextProvider>';

export const OnboardingStatusesContext =
  createContext<OnboardingStatusesContext>({
    get statuses(): never {
      throw new Error(MISSING_ONBOARDING_STATUS_CONTEXT_PROVIDER);
    },
    get areStatusesLoading(): never {
      throw new Error(MISSING_ONBOARDING_STATUS_CONTEXT_PROVIDER);
    },
    get errorLoadingStatuses(): never {
      throw new Error(MISSING_ONBOARDING_STATUS_CONTEXT_PROVIDER);
    },
    get refreshStatuses(): never {
      throw new Error(MISSING_ONBOARDING_STATUS_CONTEXT_PROVIDER);
    },
  });

export const useOnboardingStatuses: () => OnboardingStatusesContext = () => {
  return useContext<OnboardingStatusesContext>(OnboardingStatusesContext);
};

interface Props {
  children: React.ReactNode;
}

export default function OnboardingStatusesContextProvider({
  children,
}: Props): React.ReactElement {
  const { data, loading, error, refetch } =
    useOnboardingStatusesContextUserQuery({
      fetchPolicy: 'cache-and-network',
    });
  const { isLexEnabled } = useLex();

  const user = data?.user;
  const coach = user?.coach;

  const contextVal: OnboardingStatusesContext = useMemo(() => {
    if (user == null || coach == null) {
      return {
        statuses: null,
        areStatusesLoading: loading,
        errorLoadingStatuses: error,
        refreshStatuses: refetch,
      };
    } else {
      const profileDone =
        !isEmptyString(user.firstName) &&
        !isEmptyString(user.lastName) &&
        coach.geoLocation != null &&
        user.pictureLink != null;

      const categoryInfo = coach.categoryInformationList ?? [];
      const categoriesDone =
        categoryInfo.length > 0 &&
        (categoryInfo.length <= MAXIMUM_CATEGORY_LIMIT ||
          coach.id == SUBSCRIBER_DUMMY_COACH_URN) &&
        categoryInfo.some((c) => c.isCategoryComplete);

      // If this is new experience, then the pricing services is done once the coach views the pricing page
      // otherwise, it is if they have configured their hourly rate
      const pricingServicesDone = isLexEnabled(
        LexSlug.FEATURE_PACKAGE_HOURLY_UNIFICATION,
      )
        ? !!coach.completedPricingOnboarding
        : coach.hourlyRate != null;

      const onboardingSessionDone = coach.onboardingSessionAttended === true;

      const stripeDone = coach.stripeActivated === true;

      const availabilityDone = isAvailabilityOnboarded(
        coach.availability?.defaultAvailability,
      );

      return {
        statuses: {
          profileDone,
          categoriesDone,
          pricingServicesDone,
          onboardingSessionDone,
          stripeDone,
          availabilityDone,
        },
        areStatusesLoading: loading,
        refreshStatuses: refetch,
      };
    }
  }, [coach, error, loading, user, refetch]);

  return (
    <OnboardingStatusesContext.Provider value={contextVal}>
      {children}
    </OnboardingStatusesContext.Provider>
  );
}
